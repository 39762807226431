import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap"
import { db } from "../../firebase";

const NamesToDrawCard = ({ handleRender, version, docID, claimed, raffleInfo, participantInfo, previousRoundInfo, unclaimed }) => {
	const [isLoading, setLoading] = useState(false);
	const handleClick = () => setLoading(true);
	
	const [bumpedUser, setBumpedUsers] = useState([]);
	const handleCheckboxChange = (data) => {
		const isChecked = bumpedUser.some(bumpedUser => bumpedUser.username === data.username);
		if (isChecked) {
			setBumpedUsers(
				bumpedUser.filter(
			  		(bumpedUser) => bumpedUser.username !== data.username
				)
			);
		}
		else {
			setBumpedUsers(bumpedUser.concat(data));
		}
	};

	function getRandomIntInclusive(min, max) {
		// The maximum is inclusive and the minimum is inclusive
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	async function updateDBRound(user, index, round) {
		if ((user === "") || (user == null)) {
			return;
		}

		const docRef = doc(db, version, docID, "participants", user);
		const updatedData = {
			roundPulled: round,
			positionPulled: index
		};

		await updateDoc(docRef, updatedData);

		// await updateDoc(docRef, updatedData)
		// 	.then(
		// 		docRef => {
		// 			console.log("updated round pulled db for ", user);
		// 		}
		// 	);
	}

	async function updateDBClaimed(user, round) {
		if ((user === "") || (user == null)) {
			return;
		}

		const docRef = doc(db, version, docID, "participants", user);
		const updatedData = {
			roundClaimed: round
		};

		await updateDoc(docRef, updatedData);

		// await updateDoc(docRef, updatedData)
		// 	.then(
		// 		docRef => {
		// 			console.log("updated claimed db for ", user);
		// 		}
		// );
	}

	async function updateDBBump(user, round, position) {
		if ((user === "") || (user == null)) {
			return;
		}

		const docRef = doc(db, version, docID, "participants", user);
		console.log(position);

		var updatedData = {}

		if (user.bumped === 1) {
			updatedData = {
				positionPulled: 0,
				roundPulled: 0,
				bumped: 0
			}
		}
		else {
			updatedData = {
				bumped: 1,
				roundPulled: round,
				positionPulled: position
			};
		}

		await updateDoc(docRef, updatedData);

		// await updateDoc(docRef, updatedData)
		// 	.then(
		// 		docRef => {
		// 			console.log("updated bumped db for ", user);
		// 		}
		// );
	}
	
	function getUsername(min, max, participantInfo, allWinners) {
		var found = false;

		while (!found) {
			const winningTicket = getRandomIntInclusive(min, max);

			for (let index = 0; index < participantInfo.length; index++) {
				const startTicket = participantInfo.at(index).startTicket;
				const endTicket = participantInfo.at(index).endTicket;
				const user = participantInfo.at(index).username;
				if ((!allWinners.includes(user))
					&& (winningTicket >= startTicket)
					&& (winningTicket <= endTicket)
					&& (participantInfo.at(index).roundClaimed === 0)
					&& (participantInfo.at(index).roundPulled === 0) )
				{
					found = true;
					return user;
				}
			}
		}
	}

	const drawNames = () => {
		return new Promise(async (resolve, reject) => {
			const docRef = doc(db, version, docID);
			const newRound = raffleInfo?.currentRound + 1;
			const updatedData = {
				currentRound: newRound
			};

			await updateDoc(docRef, updatedData);

			const toDraw = document.getElementById("number-to-draw").value;
			const min = 1;
			const max = raffleInfo.totalTickets;
			const allWinners = [];
			const drawingMin = (toDraw < unclaimed.length) ? toDraw : unclaimed.length;

			console.log("drawing new winners:");
			for (let index = 0; index < drawingMin; index++) {
				const winningUser = getUsername(min, max, participantInfo, allWinners);
				allWinners.push((winningUser));
				updateDBRound(winningUser, index + 1, newRound);
				console.log(" - position:", index + 1, ", user: ", winningUser);
			}
			
			console.log("bumped winners:");
			if (newRound < 2) {
				console.log(" - none as drawing for first round");
			}
			else {
				var claimedUsers = []
				var sortedBumpedUser = bumpedUser.sort(function(a, b) {
					var keyA = a.positionPulled,
					keyB = b.positionPulled;
					if (keyA < keyB) return -1;
					if (keyA > keyB) return 1;
					return 0;
				});
				
				for (let index = 0; index < sortedBumpedUser.length; index++) {
					const user = sortedBumpedUser.at(index).username;
					updateDBBump(user, newRound, (Number(toDraw) + index + 1));
					console.log(" - position:", Number(toDraw) + index + 1, ", user: ", user);
				}

				console.log("claimed winners:");
				for (let index = 0; index < previousRoundInfo.length; index++) {
					const user = previousRoundInfo.at(index).username;
					if (!bumpedUser.some(bumped => bumped.username === user)) {
						updateDBClaimed(user, raffleInfo?.currentRound);
						console.log(" - user: ", user);
						claimedUsers.push(user);
					}
				}
			}
			resolve(1);
		});
	}
	
	useEffect(() => {
		if (isLoading) {
			drawNames().then(() => {
				setLoading(false);
				handleRender();
		  });
		}
	  }, [isLoading]);

	return (
		<Container className="main">
			current round: <b>{raffleInfo?.currentRound}</b>
			<br />
			<br />
			move to round {raffleInfo?.currentRound + 1} and draw &nbsp;
			<select id="number-to-draw">
				<option value="10">10</option>
				<option value="9">9</option>
				<option value="8">8</option>
				<option value="7">7</option>
				<option value="6">6</option>
				<option value="5">5</option>
				<option value="4">4</option>
				<option value="3">3</option>
				<option value="2">2</option>
				<option value="1">1</option>
			</select> new users
			<br />
			<br />
			<Button className="button--general" disabled={isLoading} onClick={!isLoading ? handleClick : null}>
					{ isLoading ? "drawing..." : "draw" }
			</Button>
			<br />
			<br />
			once you move on, you <b>cannot go back</b>, so make sure you are completely done before continuing!
			{/* <div className="carry-info--disabled" id="carry-info--disabled"> */}
			{/* <div className={(document.getElementById("number-to-draw")?.value < 10) ? "main" : "carry-info--disabled}"} id="carry-info--disabled"> */}
			<hr className="divider--m"/>
			select users to bump to round {raffleInfo?.currentRound + 1}
			<br />
			<br />
			<ol id="bumped-users">
				{ previousRoundInfo && (
					previousRoundInfo.map(user => {
						return (
							<li key={user.username}>
								<input 
									type="checkbox"
									name="bumped-user"
									value={user.username}
									onChange={() => handleCheckboxChange(user)}
								/>
								<label>{user.username}</label>
							</li>
						);
					}))
				}
			</ol>
		</Container>
	)
}

export default NamesToDrawCard;