import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap"
import Papa from "papaparse";
import { db } from "../../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

const DataManipulationCard = ({ handleRender, version }) => {
    // It will store the file uploaded by the user
    const [file, setFile] = useState("");
	const [isLoading, setLoading] = useState(false);
 
    // This function will be called when the file input changes
    const handleFileChange = (e) => {
        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            setFile(inputFile);
        }
    };

	const handleClick = () => setLoading(true);

    const parsing = () => {
		return new Promise((resolve, reject) => {
			const serverTime = serverTimestamp();
			setLoading(true);

			// Initialize a reader which allows user to read any file or blob.
			const reader = new FileReader();
	
			// Event listener on reader when the file loads, we parse it and set the data.
			reader.onload = async ({ target }) => {
				const csv = Papa.parse(target.result, { header: true } );
				const parsedData = csv?.data;
				const columns = Object.keys(parsedData[0]);
				const fullFilename = file.name;
				const filename = fullFilename.substring(0, fullFilename.indexOf("."));
				
				await setDoc(doc(db, version, filename), {
					currentRound: 0,
					totalTickets: Number(parsedData[parsedData.length - 1].endTicket),
					postedOn: serverTime
				});

				for (let index = 0; index < parsedData.length; index++) {
					const element = parsedData[index];
					await setDoc(doc(db, version, filename, "participants", element.username), {
						username: element.username,
						startTicket: Number(element.startTicket),
						endTicket: Number(element.endTicket),
						roundClaimed: 0,
						positionClaimed: 0,
						roundPulled: 0,
						positionPulled: 0,
						bumped: 0
					});
				}
				handleRender();

				resolve(columns);
			};

			reader.onerror = reject;

			reader.readAsText(file);
		});
    };

	useEffect(() => {
		if (isLoading) {
		  parsing().then(() => {
			setLoading(false);
		  });
		}
	  }, [isLoading]);

	return (
		<Container className="bottom">
			<b>only use the following forms if you know exactly what you're doing!</b>
            <br />things can go wrong very easily
			<br />by doing this, you are deleting the previous raffle data 
			<br />
			<form>
				<input
					type="file"
					id="csvInput"
					name="file"
					onChange={handleFileChange}
					accept=".csv"
				/>
                <Button className="button--general" disabled={isLoading} onClick={!isLoading ? handleClick : null}>
					{ isLoading ? "loading..." : "upload csv" }
				</Button>
            </form>
		</Container>
	)
}

export default DataManipulationCard;