import { Container } from "react-bootstrap"


const PreviousRoundsCard = ({ claimed }) => {	
	return (
		<Container className="main">
			All users who've claimed prizes:
			<br />
			<ol reversed>
				{ 
					Object.keys(claimed).map(function(username) {
						return <li key={username.username}>{claimed[username].username} [{claimed[username].roundClaimed}]</li>;
					})
				}
				{/* {
					claimed.map(user => {
						return <li key={user}>{claimed[user].username} [{claimed[user].roundClaimed}]</li>;
					})
				} */}
			</ol>
		</Container>
	)
}

export default PreviousRoundsCard;