import "./App.css";
import "./custom.css";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import IfPage from "./pages/IfRaffle";
import OofPage from "./pages/OofRaffle";
import Navigation from "./components/navigation/Navigation";

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
	const [rerenderIF, setRerenderIF] = useState(false);
	const [rerenderOOF, setRerenderOOF] = useState(false);

	return (
		<div className="base-layer">
			<Navigation />
			<header>
				<h1><center>ShaDOM Raffle Drawing Application</center></h1>
			</header>
			<Routes>
				<Route path="/if_raffle" element={<IfPage rerender={rerenderIF} setRerender={setRerenderIF} key={rerenderIF} />} />
				<Route path="/oof_raffle" element={<OofPage rerender={rerenderOOF} setRerender={setRerenderOOF} key={rerenderOOF} />} />
			</Routes>
		
		</div>
	);
}

export default App;
