import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../firebase';

import DataManipulationCard from "../components/cards/DataManipulationCard";
import NamesToDrawCard from "../components/cards/NamesToDrawCard";
import PostCodeCard from "../components/cards/PostCodeCard";
import PreviousRoundsCard from "../components/cards/PreviousRoundsCard";
import TicketCard from "../components/cards/TicketCard";

const version = "oofRaffle";

function OofPage({rerender, setRerender}) {
	const [claimed, setClaimed] = useState(["none"]);
	const [docID, setDocID] = useState();
	const [participantInfo, setParticipantInfo] = useState();
	const [previousRoundInfo, setPreviousRoundInfo] = useState(["none"]);
	const [raffleInfo, setRaffleInfo] = useState();
	const [render1, setRender1] = useState(1);
	const [render2, setRender2] = useState(2);
	const [unclaimed, setUnclaimed] = useState(["none"]);

	const handleRender1 = async () => {
		setRender1(-render1);
		setRerender(!rerender);
	}

	const handleRender2 = async () => {
		setRender2(-render2);
		setRerender(!rerender);
	}
	
	const fetchClaimed = async () => {
		const q = query(collection(db, version), orderBy("postedOn", "desc"), limit(1));

		const snap = await getDocs(q);
		
		snap.forEach(async (doc) => {
			const q2 = query(collection(db, version, doc.id, "participants"), where("roundClaimed", "!=", 0), orderBy("roundClaimed", "desc"), orderBy("positionPulled", "desc"));
			
			const snap2 = await getDocs(q2);
			var data2 = []
			snap2.forEach((doc2) => {
				data2.push(doc2.data());
			});
			setClaimed(data2);
		});
	}

	const fetchUnclaimed = async () => {
		const q = query(collection(db, version), orderBy("postedOn", "desc"), limit(1));

		const snap = await getDocs(q);
		
		snap.forEach(async (doc) => {
			const q2 = query(collection(db, version, doc.id, "participants"), where("roundPulled", "==", 0));
			
			const snap2 = await getDocs(q2);
			var data2 = []
			snap2.forEach((doc2) => {
				data2.push(doc2.data());
			});
			setUnclaimed(data2);
		});
	}

	const fetchParticipantInfo = async () => {
		const q = query(collection(db, version), orderBy("postedOn", "desc"), limit(1));

		const snap = await getDocs(q);
		
		snap.forEach(async (doc) => {
			const q2 = query(collection(db, version, doc.id, "participants"), orderBy("username"));
			
			const snap2 = await getDocs(q2);
			var data2 = []
			snap2.forEach((doc2) => {
				data2.push(doc2.data());
			});
			setParticipantInfo(data2);
		});
	}

	const fetchPreviousRoundInfo = async () => {
		const q = query(collection(db, version), orderBy("postedOn", "desc"), limit(1));

		const snap = await getDocs(q);
		
		snap.forEach(async (doc) => {
			const round = doc.data().currentRound;
			if (round > 0) {
				const q2 = query(collection(db, version, doc.id, "participants"), where("roundPulled", "==", round), orderBy("positionPulled"));
				
				const snap2 = await getDocs(q2);
				var data2 = []
				snap2.forEach((doc2) => {
					data2.push(doc2.data());
				});
				setPreviousRoundInfo(data2);
			}
		});
	}
	
	const fetchRaffleInfo = async () => {
		const q = query(collection(db, version), orderBy("postedOn", "desc"), limit(1));

		const snap = await getDocs(q);
		
		snap.forEach((doc) => {
			const data = doc.data();
			setRaffleInfo(data);
			setDocID(doc.id);
		});
	}

	useEffect(() => {
		fetchRaffleInfo();
		fetchParticipantInfo();
		fetchPreviousRoundInfo();
		fetchClaimed();
		fetchUnclaimed();
	}, []);

	return (
		<div>
			<Container>
			<h3>OOF Raffle</h3>
				<Row>
					<Col>
						<TicketCard {...raffleInfo}/>
						<hr className="divider--m"/>
						<NamesToDrawCard handleRender={handleRender1} version={version} docID={docID} claimed={claimed} raffleInfo={raffleInfo} participantInfo={participantInfo} previousRoundInfo={previousRoundInfo} unclaimed={unclaimed} key={"NamesToDrawCard"}/>
					</Col>
					<Col>
						<PostCodeCard raffleInfo={raffleInfo} previousRoundInfo={previousRoundInfo} key={"PostCodeCard"}/>
					</Col>
				</Row>
					<hr className="divider--m"/>
					<PreviousRoundsCard claimed={claimed} key={"PreviousRoundsCard"}/>
					<hr className="divider--m"/>
					<DataManipulationCard handleRender={handleRender2} version={version} key={"DataManipulationCard"}/>
				</Container>
		</div>
	)
}

export default OofPage;
