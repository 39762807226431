// const cors = require("cors")({ origin: true });
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCZGYxyhmCmSmYWNMp9r6BY5DcC7ur9a1M",
	authDomain: "raffle-a656b.firebaseapp.com",
	databaseURL: "https://raffle-a656b-default-rtdb.firebaseio.com",
	projectId: "raffle-a656b",
	storageBucket: "raffle-a656b.appspot.com",
	messagingSenderId: "589926805928",
	appId: "1:589926805928:web:e9104722b7a0fd201aad9d",
	measurementId: "G-KC5PQWE0HV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

export { app, db, auth };

// // Create and deploy your first functions
// // https://firebase.google.com/docs/functions/get-started
//
// exports.helloWorld = functions.https.onRequest((request, response) => {
//     functions.logger.info("Hello logs!", { structuredData: true });
//     response.send("Hello from Firebase!");
// });