import { useState } from "react";
import { Button, Container } from "react-bootstrap"

const PreviousRoundsCard = ({ raffleInfo, previousRoundInfo }) => {
	const [copyButton, setCopyButton] = useState("copy post code");

	const copyCode = () => {
		var code, codeContent;
		code = document.getElementsByClassName("post-code")[0];
		codeContent = code.innerText;

		navigator.clipboard
			.writeText(codeContent)
			.then(() => {
				setCopyButton("post code copied!");
				setTimeout(function() {
					setCopyButton("copy post code");
				}, 5000)
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	return (
		<Container>
			<div className="main">
				<Button className="button--general" onClick={copyCode}>{copyButton}</Button>{' '}
				<div id="post-code" className="post-code">
					[center][b][size=4]Round {raffleInfo?.currentRound} has officially begun![/b][/size][/center]
					<br />
					<br />
					<br /> [center][b]Congratulations to our round {raffleInfo?.currentRound} winners:[/b][/center]
					<br />
					<br />
					<br /> [list=1]
					{
						previousRoundInfo.map(user => {
							return <div key={user.username}>[*]@{user.username} {user.bumped ? "(bumped)": ""}</div>;
						})
					}
					[/list]
					<br />
					<br />
					<br /> [center]You have [b]24 hours[/b] to respond or you will be bumped to the end of the next round. If you do not respond to the bump, you will have to be drawn again to win a prize. [b]I am subscribed[/b] to the thread, so
					there's no need to ping.[/center]
					<br />
					<br />
					<br /> [center]Respond to your winning ticket by listing the number of choices that correspond to your number in the list. (For example, 5 should list five prize options from most to least wanted, while 1 should only list one).
					You will receive one of your prize choices based on availability.[/center]
					<br />
					<br />
					<br /> [center]Spectator Pinglist: [insert spectator pinglist][/center]
				</div>
			</div>
		</Container>
	)
}

export default PreviousRoundsCard;