import { Container } from "react-bootstrap"

function numberWithCommas(x) {
	var temp = "" + x
    return temp.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const TicketCard = (raffleInfo) => {
	return (
		raffleInfo && (
			<Container className="main">
				total ticket count: <b>{numberWithCommas(raffleInfo.totalTickets)}</b>
				<br />if this looks right, proceed!
				<br />if this does not look right, shout at bee
			</Container>
		)
	)
}

export default TicketCard;