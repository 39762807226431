import { Nav, Navbar, Container } from 'react-bootstrap';

function Navigation() {
  return (
    <Navbar>
      <Container>
          <Nav>
            <Nav.Link href="/if_raffle">IF Raffle</Nav.Link>
            <Nav.Link href="/oof_raffle">OOF Raffle</Nav.Link>
          </Nav>
      </Container>
    </Navbar>
  );
}

export default Navigation;